@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/aaq1fxx.css');
/* Century Gothic */
@import url('https://use.typekit.net/urf0mxp.css');
@layer base {
    h1 {
        @apply text-[1.25rem] md:text-[2rem] xl:text-[2.5rem] font-medium;
    }
    h2 {
        @apply text-[1.25rem] md:text-[2rem] xl:text-[2.5rem] font-medium;
    }
}
h3 {
    @apply text-[1.25rem] md:text-[1.5rem] xl:text-[1.75rem] font-medium;
}
h5 {
    @apply text-[1.1rem] md:text-[1.25rem] xl:text-[1.5rem] font-medium;
}
p {
    @apply text-base lg:text-[1.4rem] lg:leading-[30px];
}
html,
body {
    margin: 0;
    padding: 0;
    background-color: black;
    color: #fff;
    height: -webkit-fill-available;
    /* overflow: hidden; */
}
.backdropfilter {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 10;
    background-color: 'transparent';
    border-image-source: 'linear-gradient(45deg, rgba(0, 230, 147, 0.6), rgba(0, 223, 224, 0.6))';
    border-image-slice: 1;
    border-radius: '8px';
    box-shadow: '0 0 10px rgba(0, 230, 147, 0.8), 0 0 20px rgba(0, 223, 224, 0.6)';
}
.bg-shadow-left {
    opacity: 1;
    z-index: 1;
    border-radius: 50%;
    width: 50%;
    height: 70%;
    position: absolute;
    top: 20%;
    left: -20%;
    filter: blur(250px) !important;
    background: radial-gradient(
        50% 50%,
        #07edc4f0 0%,
        #0785dfa3 100%
    ) !important;
}
.bg-shadow-right {
    opacity: 1;
    z-index: 1;
    border-radius: 50%;
    width: 50%;
    height: 70%;
    position: absolute;
    top: 20%;
    /* right: -20%; */
    z-index: -100 !important;
    filter: blur(250px) !important;
    background: radial-gradient(
        50% 50%,
        #07edc4f0 0%,
        #0785dfa3 100%
    ) !important;
}
*,
:after,
:before {
    box-sizing: border-box;
}
.btnw {
    background: linear-gradient(
            268.23deg,
            rgba(4, 85, 78, 0.32) -11.01%,
            rgba(27, 35, 52, 0.78) 113.06%
        ),
        linear-gradient(
            268.23deg,
            rgba(242, 242, 242, 0.42) -4.29%,
            rgba(242, 242, 242, 0.13) 105.6%
        );
    border-radius: 7px;
    border: 1px solid
        linear-gradient(
            268.23deg,
            rgba(242, 242, 242, 0.42) -4.29%,
            rgba(242, 242, 242, 0.13) 105.6%
        );
    /* border-image-source: linear-gradient(268.23deg, rgba(242, 242, 242, 0.42) -4.29%, rgba(242, 242, 242, 0.13) 105.6%); */
    border: 1px solid #6c757d;
}
.img-wrap {
    background: linear-gradient(
            244.32deg,
            #04554e 3.63%,
            rgba(27, 35, 52, 0.3) 102.11%
        ),
        linear-gradient(
            249.47deg,
            rgba(216, 216, 216, 0.71) -7.17%,
            rgba(216, 216, 216, 0) 103.73%
        );
    border-radius: 7px;
    border: 1px solid;
    border-image-source: linear-gradient(
        249.47deg,
        rgba(216, 216, 216, 0.71) -7.17%,
        rgba(216, 216, 216, 0) 103.73%
    );
}

* {
    font-family: 'magistral', sans-serif;
}

/* ::-webkit-scrollbar {
    width: 0.5em;
  } */

/* ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  } */
.nav-link {
    font-weight: 600;
    opacity: 1;
    background: linear-gradient(
        93.93deg,
        rgba(4, 85, 78, 0.5) -4.21%,
        rgba(0, 0, 0, 0.79) 100.44%
    );
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
/* If using React, set height on the root div as well */
#root {
    height: -webkit-fill-available;
}
.gradient {
    background-image: url('/public/bg-desktop.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.video {
    margin: 4rem auto 0 auto;
}

@media only screen and (max-width: 640px) {
    .gradient {
        background-image: url('/public/bg-mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media only screen and (min-width: 2324px) and (max-width: 3840px) {
    p {
        font-size: 1.8rem;
    }
    #tabs-header > div {
        font-size: 1.8rem;
    }
    #nav a {
        font-size: 1.5rem;
    }
    #twoPeople {
        padding-top: 10%;
    }
}
.hide-scrollbar::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
}
