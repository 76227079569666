.rotate {
  position: fixed;
  opacity: 0.3;
  animation: rotate 70s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerrr {
  padding: 20px;
  max-width: 100vw;
  background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.753),
      rgba(0, 0, 0, 0.753),
      rgba(2, 27, 33, 0.747)
    ),
    url("./main_img.png") no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100%;
  position: relative;
}

.form-container {
  padding: 10px;
  width: -webkit-fill-available;
  color: #47dca5;
  border-radius: 15px;
  border: 1px solid
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 24%,
      rgba(255, 255, 255, 0.001) 100%
    );
  position: relative;
  z-index: 2;
}

.form-container label {
  display: block;
  margin-bottom: 8px;
  color: #47dca5;
  white-space: nowrap;
}

.form-container .border-container {
  position: relative;
  display: inline-block;
}
.form-container .border-container .border {
  font-family: "Magistral", Arial, sans-serif;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.24),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  padding: 15px;
  font-size: 16px;
  border: 1px solid transparent;
  position: relative;
  /* z-index: 1; */
}
.form-container .border-container .border1 {
  font-family: "Magistral", Arial, sans-serif;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.24),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  border: 1px solid transparent;
  position: relative;
  /* z-index: 1; */
}
.form-container .border-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgba(49, 49, 49, 0)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 0;
}

.form-container .border-container .react-select__menu {
  position: relative !important;
  z-index: 2 !important;
}

.form-container .border-container .react-select__option {
  position: relative !important;
  z-index: 2 !important;
  background-color: #808080 !important;
  color: #000 !important;
}

input:is(
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active,
    :-internal-autofill-selected
  ) {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
  transition: background-color 1000000s ease-in-out 0s;
}

.form-container select option {
  background: rgba(7, 58, 7, 0.3);
}

.form-container input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-container input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  color: #808080;
  cursor: pointer;
}

.form-container input:focus,
select:focus {
  outline: none;
}

.form-container input[type="radio"] {
  padding: 1px;
  margin: 0 10px 0 20px;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid rgb(0, 0, 0);
  background-color: rgba(0, 0, 0);
}

.form-container input[type="radio"]:checked {
  background-color: rgb(156, 227, 37);
  border: 2px solid rgb(156, 227, 37);
  padding: 1px;
}

.form-container label {
  color: rgba(255, 255, 255, 0.6);
}

.form-container .submit-form {
  display: flex;
}

.form-container .submit-form .border-btn {
  font-family: "Magistral", Arial, sans-serif;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.24),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 8px;
  color: #fff;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 16px;
  border: 0.05px solid;
  z-index: 1;
}

.form-container .submit-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 2px;

  pointer-events: none;
  z-index: 0;
}

.form-container .flare {
  bottom: -2px;
  width: 3px;
  height: 2px;
  background: rgb(255, 255, 255, 0.7);
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation 15s ease-in-out infinite;
  z-index: 1;
}
@keyframes flare-animation {
  0% {
    left: 1%;
    opacity: 1;
  }
  33.33% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    left: 99%;
    opacity: 1;
  }
  33.34% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.form-container .flare2 {
  position: absolute;
  bottom: 0;
  width: 3px;
  height: 2px;
  background: rgb(255, 255, 255, 0.5);
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
  border-radius: 50%;
  animation: flare-animation2 15s ease-in-out infinite;
  z-index: 1;
}
@keyframes flare-animation2 {
  0% {
    right: 1%;
    opacity: 1;
  }
  33.33% {
    /* 5 seconds of 70 seconds is approximately 7.14% */
    right: 99%;
    opacity: 1;
  }
  33.34% {
    opacity: 0; /* Disappear */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

/* Section 1 styles */
.section1 {
  flex: 1;
  margin-right: 20px;
}

div.error {
  font-size: 0.8em;
  color: rgb(255, 255, 255);
  float: right;
  margin-top: 2px;
}

option {
  background-color: #808080 !important;
  color: #000 !important;
  z-index: 2;
}


@media only screen and (max-width: 468px) {
  .hideOnPhone {
    display: none;
  }
  .emailbox {
    display: block !important;
  }
  .title {
    font-size: 30px !important; 
    margin-top: 10vh  !important; 
  }
  .sub-title {
    font-size: 16px  !important; 
  }
  .submit-form {
    margin-top: 3vh
  }
  .border-container {
    width: 100%;
  }
  .privacy {
    display: flex !important;
  }
}
